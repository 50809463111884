import React, { useEffect, useState } from "react";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import VerifiedUserTwoToneIcon from "@mui/icons-material/VerifiedUserTwoTone";
import StarTwoToneIcon from "@mui/icons-material/StarTwoTone";
import MyInput from "../../components/MyInput/index.tsx";
import MyButton from "../../components/MyButton/index.tsx";
import config from "../../config.json";
import { call } from "../../api/call.tsx";
import { enqueueSnackbar } from "notistack";

const services = [
	{
		title: "استقبال بلاغات الاحتيال",
		description: ["تلقي بلاغات المُستهلكين حول عمليات الشراء عبر الإنترنت من مواقع أو أشخاص غير موثوقين.", "الإبلاغ عن حالات عدم استلام المنتجات المُدفوعة ثمنها."],
	},
	{
		title: "استرداد الأموال المسروقة",
		description: ["مساعدة المُستهلكين في استعادة الأموال المُستولى عليها نتيجة للاحتيال الإلكتروني.", "تقديم الدعم القانوني والتحليلات اللازمة لاسترداد الأموال."],
	},
	{
		title: "مكافحة الغش التجاري",
		description: ["تمكين المُستهلكين من الإبلاغ عن حالات الغش التجاري مثل شراء العقارات من شركات وهمية أو بضائع غير مطابقة للمواصفات.", "اتخاذ الإجراءات اللازمة ضد البائعين المُخالفين."],
	},
	{
		title: "معالجة تأخير أو عدم استلام البضائع",
		description: ["تقديم المساعدة للمُستهلكين الذين لم يتلقوا طلباتهم من المتاجر الإلكترونية أو البائعين عبر الإنترنت.", "متابعة الشكاوى والتواصل مع البائعين لضمان حل المشكلة."],
	},
	{
		title: "حماية حقوق العمال في عمليات الاستقدام",
		description: ["تلقي بلاغات حول عمليات الاستقدام الوهمية وعدم استرداد الأموال.", "تقديم الدعم القانوني للمُتضررين من عمليات الاستقدام غير القانونية."],
	},
];

const Home = () => {
	const [fullName, setFullname] = useState("");
	const [email, setEmail] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [details, setDetails] = useState("");
	const [isLoading, setIsLoading] = useState(false);

	const navigate = useNavigate();

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	}, []);

	const onBannerArrowClick = () => {
		window.scrollTo({
			top: 380,
			behavior: "smooth",
		});
	};

	const generateRandomNumber = (length = 9) => {
		return Math.floor(Math.pow(10, length - 1) + Math.random() * 9 * Math.pow(10, length - 1));
	};

	const onRegister = async (e: any) => {
		e.preventDefault();
		setIsLoading(true);
		const orderNumber = generateRandomNumber();
		const request = {
			full_name: fullName,
			location: "",
			email: email,
			phone_number: phoneNumber,
			details: details,
			owner_id: process.env.REACT_APP_OWNER_ID || 1,
			order_number: orderNumber,
			source: config.name,
		};
		const Buffer = require("buffer/").Buffer;
		const token = Buffer.from(`${config.auth.username}:${config.auth.password}`, "utf8").toString("base64");
		const headers = {
			Authorization: `Basic ${token}`,
		};
		try {
			const response = await call("POST", "shakwa/add-new-lead", request, headers);
			if (response.insertId) {
				e.target.reset();
				setIsLoading(false);
				navigate(`/thank-you/${orderNumber}`);
			} else {
				enqueueSnackbar("هناك خطأ ما. حاول مرة اخرى", { variant: "error", preventDuplicate: true, autoHideDuration: 6000 });
			}
		} catch (err) {
			setIsLoading(false);
		}
	};

	return (
		<div className="web-page home-page">
			<div className="home-page-banner">
				<div className="home-page-banner-content">
					<div className="home-page-banner-text">
						<p className="home-banner-web-title">حماية حقوقك... مسؤوليتنا!</p>
						<p className="home-banner-web-description">بوابة إلكترونية لضمان تجربة تسوق آمنة وعادلة</p>
					</div>
					<KeyboardArrowDownIcon className="banner-arrow-icon-btn" onClick={onBannerArrowClick} />
				</div>
			</div>
			<div className="home-page-content">
				<div className="home-page-container">
					<div className="home-page-section">
						<div className="home-section-title-wrapper">
							<p className="home-section-title">خدماتنا</p>
							<p className="home-section-description">حماية المُستهلك من الاحتيال الإلكتروني</p>
						</div>
						<div className="services-section-container">
							{services.map((service: any, index: number) => (
								<div key={index} className="service-box">
									<div className="service-box-icon-wrapper">
										<VerifiedUserTwoToneIcon className="service-box-icon" />
									</div>
									<p className="service-box-data-title">{service.title}</p>
								</div>
							))}
						</div>
						<p className="services-end-section">نسعى من خلال خدماتنا المُحسّنة إلى توفير بيئة آمنة وموثوقة للتجارة الإلكترونية في.</p>
					</div>

					<div className="home-page-section">
						<div className="form-section-container">
							<form className="inputs-form" onSubmit={onRegister}>
								<div className="form-section-title-wrapper">
									<p className="form-section-title">احصل على استشارة مجانية من خبرائنا</p>
									<p className="form-section-description">شاركنا تفاصيل المشكلة لنقدم لك حلاً مناسباً</p>
								</div>
								<div className="form-inputs-wrapper">
									<MyInput label="اسمك الكامل" value={fullName} onChangeValue={(e: any, value: string) => setFullname(value)} required />
									<MyInput
										label="رقم هاتفك"
										value={phoneNumber}
										maxLength={12}
										onChangeValue={(e: any, value: string) => setPhoneNumber(value)}
										type="number"
										inputMode="numeric"
										required
										onWheel={(e: any) => e.target.blur()}
									/>
									<MyInput label="بريدك الالكتروني" value={email} onChangeValue={(e: any, value: string) => setEmail(value)} type="email" required maxLength={45} />
									<MyInput label="وصف المشكلة" value={details} maxLength={800} onChangeValue={(e: any, value: string) => setDetails(value)} multiline rows={5} name="details" required />
								</div>
								<div className="form-action-buttons">
									<MyButton type="submit" buttonStyle="primary" isLoading={isLoading}>
										تسجيل
									</MyButton>
								</div>
							</form>
						</div>
					</div>

					<div className="home-page-section">
						<div className="home-section-title-wrapper">
							<p className="home-section-title" style={{ textAlign: "center" }}>
								كيف نساعدك
							</p>
							<p className="home-section-description" style={{ textAlign: "center" }}>
								حلول ذكية لحماية أموالك و حقوقك الإلكترونية
							</p>
						</div>
						<div className="help-section-container">
							{services.map((service: any, index: number) => (
								<div
									key={index}
									className="help-box"
									style={{
										flexDirection: index % 2 === 1 ? "row" : "row-reverse",
										background: index % 2 === 1 ? "linear-gradient(90deg, var(--lightSecondary) 0%, var(--onBackground), transparent)" : "linear-gradient(270deg, var(--lightTint) 0%, var(--onBackground), transparent)",
									}}
								>
									<div className="help-box-icon-wrapper">
										<StarTwoToneIcon className="help-box-icon" />
									</div>
									<div className="help-box-data">
										<p className="help-box-data-title">{service.title}</p>
										{service.description.map((describe: string, d: number) => (
											<p key={d} className="help-box-data-description">
												{describe}
											</p>
										))}
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Home;
