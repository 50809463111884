import axios, { AxiosHeaders, Method, RawAxiosRequestHeaders } from "axios";
import config from "../config.json";
import { deepParseJSON } from "../assets/helpers/objectsHelper.tsx";

type MethodsHeaders = Partial<
	{
		[Key in Method as Lowercase<Key>]: AxiosHeaders;
	} & { common: AxiosHeaders }
>;

export async function call(method: "GET" | "POST", rest: string, request: object = {}, headers: (RawAxiosRequestHeaders & MethodsHeaders) | AxiosHeaders = {}) {
	let response: any = null;
	try {
		response = await axios({
			method: method,
			headers: headers,
			url: config.server + "/" + rest,
			data: request,
		});
		const res = response?.data;
		deepParseJSON(res);
		return res;
	} catch (error: any) {
		if (error?.response) {
			if (process.env.REACT_APP_ENV === "development") {
				console.error(error);
			}
			throw error;
		} else if (error.message === "Network Error") {
			throw new Error("Please check your internet connection and try again.");
		} else {
			throw new Error("An error occurred. Please try again.");
		}
	}
}
