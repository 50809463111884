import React, { useEffect } from "react";
import "./styles.scss";

const TermsAndConditions = () => {
	useEffect(() => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	}, []);

	return (
		<div className="web-page terms-page">
			<h1>الشروط والأحكام</h1>
			<p>
				مرحبا بكم في موقعنا. إذا واصلت تصفح واستخدام هذا الموقع، فإنك توافق على الالتزام والامتثال للشروط والأحكام التالية، والتي تحكم مع سياسة الخصوصية لدينا علاقتنا بك فيما يتعلق بهذا الموقع. إذا كنت لا توافق على أي جزء من هذه
				الشروط والأحكام، فيرجى عدم استخدام موقعنا.
			</p>

			<h2>استخدام الموقع</h2>
			<p>يخضع استخدامك لهذا الموقع للشروط التالية:</p>
			<ul>
				<li>محتوى صفحات هذا الموقع هو لمعلوماتك العامة واستخدامك فقط. يمكن تغييره دون إشعار مسبق.</li>
				<li>
					لا نقدم نحن ولا أي طرف ثالث أي ضمان فيما يتعلق بدقة أو توقيت أو أداء أو اكتمال أو ملاءمة المعلومات والمواد الموجودة أو المعروضة على هذا الموقع لأي غرض معين. إنك تقر بأن مثل هذه المعلومات والمواد قد تحتوي على أخطاء أو عدم
					دقة، ونحن نستبعد صراحة المسؤولية عن أي من هذه الأخطاء أو عدم الدقة إلى أقصى حد مسموح به بموجب القانون.
				</li>
				<li>استخدامك لأي معلومات أو مواد على هذا الموقع هو على مسؤوليتك الخاصة، ولن نكون مسؤولين. تقع على عاتقك مسؤولية التأكد من أن أي منتجات أو خدمات أو معلومات متاحة من خلال هذا الموقع تلبي احتياجاتك الخاصة.</li>
				<li>
					يحتوي هذا الموقع على مواد مملوكة لنا أو مرخصة لنا. تتضمن هذه المواد، على سبيل المثال لا الحصر، التصميم والتخطيط والشكل والمظهر والرسومات. يحظر الاستنساخ إلا بما يتوافق مع إشعار حقوق النشر، والذي يشكل جزءًا من هذه الشروط
					والأحكام.
				</li>
				<li>جميع العلامات التجارية المستنسخة في هذا الموقع، والتي ليست مملوكة أو مرخصة للمشغل، يتم الاعتراف بها على الموقع.</li>
				<li>قد يؤدي الاستخدام غير المصرح به لهذا الموقع إلى مطالبة بالتعويض عن الأضرار و/أو يكون جريمة جنائية.</li>
				<li>قد يحتوي هذا الموقع أيضًا على روابط لمواقع أخرى. يتم توفير هذه الروابط لراحتك لتقديم مزيد من المعلومات. لا تعني أننا نؤيد الموقع/المواقع. لا نتحمل أي مسؤولية عن محتوى الموقع/المواقع المرتبطة.</li>
			</ul>

			<h2>حدود المسؤولية</h2>
			<p>لن نكون مسؤولين عن أي خسارة أو ضرر مباشر أو غير مباشر أو تبعي ينشأ عن استخدام أو عدم القدرة على استخدام المواد والمعلومات الموجودة على هذا الموقع، حتى لو كنا قد نصحنا بإمكانية حدوث مثل هذه الخسارة أو الضرر.</p>

			<h2>تعديلات على الشروط</h2>
			<p>نحتفظ بالحق في تعديل هذه الشروط والأحكام في أي وقت، وسيتم نشر أي تغييرات على هذه الصفحة. استخدامك المستمر للموقع بعد أي تغييرات يعني قبولك للشروط المعدلة.</p>

			<h2>القانون الحاكم</h2>
			<p>تخضع هذه الشروط والأحكام وتفسر وفقًا لقوانين الدولة التي نعمل فيها، وأي نزاعات تتعلق بهذه الشروط ستخضع للولاية القضائية الحصرية لمحاكم تلك الدولة.</p>

			<h2>اتصل بنا</h2>
			<p>إذا كان لديك أي أسئلة حول هذه الشروط والأحكام، يرجى الاتصال بنا عبر البريد الإلكتروني أو الهاتف المتاحين على موقعنا. نحن هنا لمساعدتك وسنقوم بالرد على استفساراتك في أقرب وقت ممكن.</p>

			<p>كل الحقوق محفوظة © 2024</p>
		</div>
	);
};

export default TermsAndConditions;
