/* eslint-disable react-hooks/exhaustive-deps */
import React, { CSSProperties } from "react";
import { Button, ButtonProps, CircularProgress } from "@mui/material";
import "./styles.scss";

interface MyButtonProps extends ButtonProps {
	buttonStyle?: "primary" | "secondary" | "outline" | "outlineSecondary" | "soft" | "disabled" | "error";
	isLoading?: boolean;
	isLoadingColor?: string;
	containerStyle?: CSSProperties;
	labelStyle?: CSSProperties;
}

function MyButton(props: MyButtonProps) {
	const { children, buttonStyle, isLoading, containerStyle = {}, isLoadingColor, disabled = false, labelStyle, ...buttonProps } = props;

	const getButtonBGStyle = () => {
		switch (buttonStyle) {
			case "secondary":
				return {
					backgroundColor: "var(--secondary)",
				};
			case "outline":
				return {
					backgroundColor: "transparent",
					borderWidth: 2,
					borderColor: "var(--tint)",
				};
			case "outlineSecondary":
				return {
					backgroundColor: "transparent",
					borderWidth: 2,
					borderColor: "var(--secondary)",
					color: "var(--secondary)",
				};
			case "soft":
				return {
					backgroundColor: "transparent",
					border: "1px solid var(--borderColor)",
					color: "var(--text)",
				};
			case "disabled":
				return {
					backgroundColor: "var(--borderColor)",
					borderWidth: 1,
					borderColor: "var(--borderColor)",
					opacity: 0.6,
				};
			case "error":
				return {
					backgroundColor: "var(--red)",
				};
			default:
				return {
					backgroundColor: "var(--tint)",
				};
		}
	};

	return (
		<Button className="my-button-container" size="medium" variant={buttonStyle?.includes("outline") ? "outlined" : "contained"} {...buttonProps} style={{ ...getButtonBGStyle(), ...containerStyle }} disabled={isLoading || disabled}>
			<label style={labelStyle}>{children}</label>
			{isLoading === true && <CircularProgress style={{ color: isLoadingColor || "#FFF", width: 20, height: 20 }} />}
		</Button>
	);
}

export default MyButton;
