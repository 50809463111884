import React, { useEffect } from "react";
import "./styles.scss";
import { NavLink, useParams } from "react-router-dom";
import ThumbUpAltTwoToneIcon from "@mui/icons-material/ThumbUpAltTwoTone";

const ThankYou = () => {
	const { orderNum } = useParams();

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	}, []);

	return (
		<div className="web-page thank-you-page">
			<p className="thank-you-title">شكرا لك</p>
			<p className="thank-you-order-num">
				رقم الطلب <label style={{ color: "var(--secondary)" }}>{orderNum}</label>
			</p>
			<ThumbUpAltTwoToneIcon className="thank-you-icon" />
			<p className="thank-you-content">تم ارسال طلبك بنجاح وسيتم التواصل معك باقرب وقت ممكن</p>
			<NavLink className="back-to-home" to="/">
				الصفحة الرئيسية
			</NavLink>
		</div>
	);
};

export default ThankYou;
