import React, { useEffect, useState } from "react";
import "./styles.scss";
import { NavLink } from "react-router-dom";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import config from "../../config.json";
import { call } from "../../api/call.tsx";

const Footer = () => {
	const [whatsappNumber, setWhatsappNumber] = useState(null);

	useEffect(() => {
		getPhoneNumber();
	}, []);

	const getPhoneNumber = async () => {
		const request = {
			column: "campaign_name",
			value: `'${config.name}'`,
			tableName: "campaigns_phone_numbers",
			selectValues: "phone_number",
		};
		const Buffer = require("buffer/").Buffer;
		const token = Buffer.from(`${config.auth.username}:${config.auth.password}`, "utf8").toString("base64");
		const headers = {
			Authorization: `Basic ${token}`,
		};
		try {
			const response = await call("POST", "common/get-entries", request, headers);
			console.log(response);
			setWhatsappNumber(response[0]?.phone_number || null);
		} catch (err) {}
	};

	return (
		<div className="footer-wrapper">
			<div className="footer">
				{whatsappNumber && (
					<div className="footer-social">
						<div className="footer-social-wrapper">
							<WhatsAppIcon style={{ color: "#075e54" }} />
							<label>{whatsappNumber}</label>
						</div>
					</div>
				)}
				<div className="footer-navs">
					<NavLink className="footer-nav" to="/privacy-policy">
						سياسة الخصوصية
					</NavLink>
					<NavLink className="footer-nav" to="/terms-and-conditions">
						الشروط والأحكام
					</NavLink>
				</div>

				<p className="footer-rights">كل الحقوق محفوظة {new Date().getFullYear()} ©</p>
			</div>
		</div>
	);
};

export default Footer;
