import React, { useEffect, useState } from "react";
import "./styles.scss";
import LocalPoliceOutlinedIcon from "@mui/icons-material/LocalPoliceOutlined";
import { NavLink } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Footer from "../Footer/index.tsx";

const navigations = [
	{
		label: "الرئيسية",
		to: "/",
	},
];

const Header = () => {
	const [isTransparent, setIsTransaprent] = useState(true);
	const [openMobileMenu, setOpenMobileMenu] = useState(false);

	useEffect(() => {
		handleScroll();
		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	const handleScroll = () => {
		if (window.scrollY > 350 || window.location.pathname !== "/") {
			setIsTransaprent(false);
		} else {
			setIsTransaprent(true);
		}
	};

	return (
		<div className={isTransparent ? "header-wrapper" : "header-wrapper scrolled"}>
			<div className="header">
				<NavLink to="/" className="header-logo">
					<p className="header-logo-label">
						<b style={{ color: "var(--tint)" }}>حماية</b> المستهلك
					</p>
					<LocalPoliceOutlinedIcon className="header-logo-icon" />
				</NavLink>
				<div className="header-nav-buttons">
					<MenuIcon
						className="mobile-menu-btn"
						onClick={() => {
							setOpenMobileMenu(true);
						}}
					/>
					<div className={openMobileMenu ? "nav-buttons visible" : "nav-buttons"}>
						<CloseIcon
							className="mobile-menu-close-btn"
							onClick={() => {
								setOpenMobileMenu(false);
							}}
						/>
						<div className="header-navigations-container">
							{navigations.map((nav: any, index: number) => (
								<NavLink key={index} className="header-nav-button" to={nav.to} onClick={() => setOpenMobileMenu(false)}>
									{nav.label}
								</NavLink>
							))}
						</div>
						<div className="mobile-header-footer">
							<Footer />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Header;
