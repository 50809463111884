import React, { useEffect } from "react";
import "./App.scss";
import { Routes, Route, Navigate } from "react-router-dom";
import createCache from "@emotion/cache";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import { CacheProvider } from "@emotion/react";
import { call } from "./api/call.tsx";
import config from "./config.json";

//Componenets
import Header from "./components/Header/index.tsx";
import Footer from "./components/Footer/index.tsx";

//Pages
import Home from "./pages/Home/index.tsx";
import PrivacyPolicy from "./pages/PrivacyPolicy/index.tsx";
import TermsAndConditions from "./pages/TermsAndConditions/index.tsx";
import ThankYou from "./pages/ThankYou/index.tsx";

const cacheRtl = createCache({
	key: "muirtl",
	stylisPlugins: [prefixer, rtlPlugin as any],
});
const rtlTheme = createTheme({ direction: "rtl" });

const App = () => {
	useEffect(() => {
		checkBlackList();
	}, []);

	const checkBlackList = async () => {
		const Buffer = require("buffer/").Buffer;
		const token = Buffer.from(`${config.auth.username}:${config.auth.password}`, "utf8").toString("base64");
		const headers = {
			Authorization: `Basic ${token}`,
		};
		try {
			const response = await call("POST", "common/is-in-blacklist", {}, headers);
			if (response.data === "banned") {
				window.location.replace("https://google.com");
			}
		} catch (err) {}
	};

	return (
		<div className="App">
			<CacheProvider value={cacheRtl}>
				<ThemeProvider theme={rtlTheme}>
					<Header />
					<div className="app-pages">
						<Routes>
							<Route path="/" element={<Home />} />
							<Route path="/thank-you/:orderNum" element={<ThankYou />} />
							<Route path="/privacy-policy" element={<PrivacyPolicy />} />
							<Route path="/terms-and-conditions" element={<TermsAndConditions />} />
							<Route path="*" element={<Navigate replace to="/" />} />
						</Routes>
					</div>
					<Footer />
				</ThemeProvider>
			</CacheProvider>
		</div>
	);
};

export default App;
