export function deepParseJSON(obj: any) {
	for (const key in obj) {
		if (typeof obj[key] === "string") {
			try {
				if (/^\s*[[{]/.test(obj[key])) {
					obj[key] = JSON.parse(obj[key]);
				}
			} catch (error: any) {
				console.error(`Error parsing JSON for key '${key}': ${error.message}`);
			}
		} else if (typeof obj[key] === "object" && obj[key] !== null) {
			deepParseJSON(obj[key]);
		}
	}
}