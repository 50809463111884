import React, { useEffect } from "react";
import "./styles.scss";

const PrivacyPolicy = () => {
	useEffect(() => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	}, []);

	return (
		<div className="web-page privacy-page">
			<h1>سياسة الخصوصية</h1>
			<p>نحن نقدر خصوصيتك ونسعى لحماية بياناتك الشخصية. توضح هذه السياسة كيفية جمع واستخدام ومشاركة وحماية المعلومات الشخصية التي نحصل عليها منك عبر موقعنا الإلكتروني.</p>

			<h2>المعلومات التي نجمعها</h2>
			<h3>المعلومات التي تقدمها مباشرةً</h3>
			<p>نقوم بجمع المعلومات التي تقدمها لنا عند التسجيل في موقعنا أو استخدام خدماتنا. تتضمن هذه المعلومات:</p>
			<ul>
				<li>اسمك الكامل</li>
				<li>رقم هاتفك</li>
				<li>بريدك الإلكتروني</li>
				<li>وصف المشكلة التي تقدمها</li>
			</ul>

			<h3>المعلومات التي نجمعها تلقائيًا</h3>
			<p>نقوم بجمع بعض المعلومات تلقائيًا عندما تتفاعل مع موقعنا. تتضمن هذه المعلومات:</p>
			<ul>
				<li>عنوان الـ IP الخاص بك</li>
				<li>نوع المتصفح والجهاز الذي تستخدمه</li>
				<li>الصفحات التي تزورها ومدة زيارتك</li>
				<li>تاريخ ووقت زيارتك لموقعنا</li>
			</ul>

			<h2>كيفية استخدام المعلومات</h2>
			<p>نستخدم المعلومات التي نجمعها من أجل:</p>
			<ul>
				<li>تقديم الخدمات التي تطلبها مثل استقبال بلاغات الاحتيال واسترداد الأموال المسروقة</li>
				<li>تحسين تجربة المستخدم على موقعنا وتخصيص المحتوى والإعلانات بناءً على اهتماماتك</li>
				<li>التواصل معك بشأن مشاكلك واستفساراتك وتقديم الدعم الفني اللازم</li>
				<li>إرسال التحديثات والنشرات الإخبارية والعروض الترويجية (إذا كنت قد وافقت على ذلك)</li>
				<li>تحليل البيانات لفهم كيفية استخدام خدماتنا وتحسينها</li>
			</ul>

			<h2>مشاركة المعلومات</h2>
			<p>نحن لا نبيع أو نؤجر معلوماتك الشخصية لأي طرف ثالث. قد نشارك معلوماتك مع:</p>
			<ul>
				<li>الموردين والمقاولين الذين يساعدوننا في تقديم الخدمات مثل معالجة المدفوعات واستضافة الموقع</li>
				<li>السلطات القانونية والتنظيمية إذا كان ذلك مطلوبًا بموجب القانون أو لحماية حقوقنا ومصالحنا القانونية</li>
				<li>شركاء الأعمال لأغراض تحليل البيانات وتطوير الأعمال (بشرط عدم الكشف عن هويتك)</li>
			</ul>

			<h2>حماية المعلومات</h2>
			<p>نستخدم إجراءات أمنية تقنية وإدارية لحماية معلوماتك الشخصية من الوصول غير المصرح به والاستخدام أو الإفصاح غير القانوني. تتضمن هذه الإجراءات:</p>
			<ul>
				<li>استخدام تقنيات التشفير لحماية البيانات أثناء نقلها</li>
				<li>تطبيق سياسات أمنية صارمة داخل منظمتنا</li>
				<li>تدريب الموظفين على أهمية الخصوصية وأمن البيانات</li>
				<li>مراجعة وتحديث إجراءات الأمان بشكل دوري لمواكبة التطورات التقنية</li>
			</ul>

			<h2>حقوقك</h2>
			<p>لديك الحق في:</p>
			<ul>
				<li>الوصول إلى بياناتك الشخصية التي نحتفظ بها</li>
				<li>تصحيح أي معلومات غير دقيقة أو غير كاملة</li>
				<li>طلب حذف بياناتك الشخصية في بعض الحالات</li>
				<li>معارضة معالجة بياناتك لأغراض معينة</li>
				<li>طلب تقييد معالجة بياناتك في ظروف محددة</li>
				<li>نقل بياناتك الشخصية إلى جهة أخرى (حق نقل البيانات)</li>
			</ul>
			<p>يمكنك ممارسة هذه الحقوق عن طريق التواصل معنا عبر معلومات الاتصال المتاحة على الموقع.</p>

			<h2>تغييرات على سياسة الخصوصية</h2>
			<p>قد نقوم بتحديث سياسة الخصوصية هذه من وقت لآخر. سيتم نشر أي تغييرات على هذه الصفحة، لذا يرجى مراجعتها بانتظام. سنقوم بإعلامك بأي تغييرات جوهرية عبر البريد الإلكتروني أو من خلال إشعار على موقعنا.</p>

			<h2>اتصل بنا</h2>
			<p>
				إذا كان لديك أي أسئلة أو استفسارات حول سياسة الخصوصية هذه أو كيفية معالجة بياناتك الشخصية، يرجى التواصل معنا عبر البريد الإلكتروني أو الهاتف المتاحين على موقعنا. نحن هنا لمساعدتك وسنقوم بالرد على استفساراتك في أقرب وقت ممكن.
			</p>

			<p>كل الحقوق محفوظة © 2024</p>
		</div>
	);
};

export default PrivacyPolicy;
