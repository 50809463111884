/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { InputAdornment, TextField, TextFieldProps } from "@mui/material";
import "./styles.scss";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

interface MyInputProps extends Omit<TextFieldProps, "variant"> {
	errorMessage?: string;
	maxLength?: number;
	value?: string;
	onChangeValue?: (e: any, value: string) => void;
}

function MyInput(props: MyInputProps) {
	const { errorMessage, type, maxLength = 45, value = "", onChangeValue, ...textFieldProps } = props;
	const [visiblePassword, setVisiblePassword] = useState(false);

	const onTextChange = (e: any) => {
		const value = e.target.value;
		const newInputValue = value.slice(0, maxLength);
		onChangeValue && onChangeValue(e, newInputValue);
	};

	return (
		<div className="my-input">
			<TextField
				size="small"
				autoComplete="off"
				variant="filled"
				{...textFieldProps}
				type={type === "password" && visiblePassword ? "text" : type}
				dir="rtl"
				sx={{
					"& label.Mui-focused": {
						color: "var(--tint)",
					},
					"& input": {
						color: "var(--text)",
					},
					"& .MuiOutlinedInput-root": {
						"& fieldset": {
							borderColor: "var(--borderColor)",
						},
						"&:hover fieldset": {
							borderColor: "var(--tint)",
						},
						"& input": {
							color: "var(--text)",
						},
						"&.Mui-focused fieldset": {
							borderColor: "var(--tint)",
						},
					},
				}}
				InputProps={{
					endAdornment: value.length > 0 && (
						<InputAdornment position="end">
							{type !== "password" ? (
								<span className="value-length">{value.length + "/" + maxLength}</span>
							) : visiblePassword ? (
								<VisibilityOffIcon className="visiblity-eye" onClick={() => setVisiblePassword(!visiblePassword)} />
							) : (
								<VisibilityIcon className="visiblity-eye" onClick={() => setVisiblePassword(!visiblePassword)} />
							)}
						</InputAdornment>
					),
				}}
				value={value}
				onChange={onTextChange}
				onWheel={(e: any) => e.target.blur()}
			/>
			{errorMessage && <p className="my-input-error-message">{errorMessage}</p>}
		</div>
	);
}

export default MyInput;
